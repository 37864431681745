type GraphQLConnection<T> = {
  edges?: {node: T}[]
  nodes?: T[]
}

export const flattenExactConnection = <T>(
  connection?: GraphQLConnection<T>,
): T[] => {
  if (!connection) {
    return []
  }

  if (connection.nodes) {
    return connection.nodes
  }

  if (connection.edges) {
    return connection.edges.map((edge) => {
      if (!edge?.node) {
        throw new Error("Connection edges must contain nodes")
      }
      return edge.node
    })
  }

  return []
}
